import { createBrowserRouter } from "react-router-dom";
import InvoiceList from "./components/invoice-list";
import InvoiceDetail from "./components/invoice-detail";
import LoginPage from "./components/login-page";
import RegisterPage from "./components/register-page";
import ProtectedRoutes from "./protected-routes";

const routes = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoutes>
        <InvoiceList />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/register",
    element: <RegisterPage />,
  },
  {
    path: "/invoice/:id",
    element: (
      <ProtectedRoutes>
        <InvoiceDetail />
      </ProtectedRoutes>
    ),
  },
]);

export default routes;

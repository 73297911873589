import { Table, Button, Form } from "react-bootstrap";
import { BsPencil, BsTrash, BsPrinter } from "react-icons/bs";
import { formatNumber } from "../helpers/utils";
const InvoiceTable = (props) => {
  const {
    invoices,
    handleEditModal,
    handleDeleteModal,
    handlePrintInvoice,
    handleSelectInvoice,
    handleSelectAllInvoices,
    selectedInvoices,
  } = props;
  return (
    <Table
      striped
      bordered
      hover
      className="mt-2 text-center"
      style={{ fontSize: "small" }}
    >
      <thead>
        <tr>
          <th>
            <Form.Check
              type="checkbox"
              onChange={handleSelectAllInvoices}
              checked={selectedInvoices.length === invoices.length}
            />
          </th>
          <th with="60">No.</th>
          <th with="150">Cabang</th>
          <th>Nomor Invoice</th>
          <th>Perusahaan</th>
          <th>Total Tagihan</th>
          <th>Tangal Pembuatan</th>
          <th>Periode</th>
          <th>Jatuh Tempo</th>
          <th width="140">Actions</th>
        </tr>
      </thead>
      <tbody>
        {invoices.map((invoice) => (
          <tr key={invoice.key}>
            <td>
              <Form.Check
                type="checkbox"
                onChange={() => handleSelectInvoice(invoice.key)}
                checked={selectedInvoices.includes(invoice.key)}
              />
            </td>
            <td>{invoice.no}</td>
            <td>{invoice.branch}</td>
            <td>{invoice.noInvoice}</td>
            <td>{invoice.company}</td>
            <td>{formatNumber(invoice.totalBill)}</td>
            <td>{invoice.createdAt}</td>
            <td>{invoice.period}</td>
            <td>{invoice.dueDate}</td>
            <td>
              <Button
                size="sm"
                variant="warning"
                onClick={() => handleEditModal(invoice)}
              >
                <BsPencil />
              </Button>
              &nbsp;
              <Button
                size="sm"
                variant="danger"
                onClick={() => handleDeleteModal(invoice)}
              >
                <BsTrash />
              </Button>
              &nbsp;
              <Button
                size="sm"
                variant="info"
                onClick={() => handlePrintInvoice(invoice)}
              >
                <BsPrinter />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default InvoiceTable;

import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase/init";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const shouldReadData = useRef(true);

  useEffect(() => {
    if (shouldReadData.current) {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          setCurrentUser(user);
        }
        setLoading(false);
      });
      shouldReadData.current = false;
      return unsubscribe; // Clean up the subscription
    }
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, loading }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyD88_MJ9r6j5Cee8iXu6KyyvgJAf58g-LY",
  databaseURL:
    "https://kimia-farma-1b26a-default-rtdb.asia-southeast1.firebasedatabase.app",
  authDomain: "kimia-farma-1b26a.firebaseapp.com",
  projectId: "kimia-farma-1b26a",
  storageBucket: "kimia-farma-1b26a.appspot.com",
  messagingSenderId: "837777724811",
  appId: "1:837777724811:web:7b8dd34a11a9f6f71fcb8e",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);

export default app;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase/init";
import { Container, Form, Button, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      window.location.href = "/";
    } catch (err) {
      if (err.code === "auth/invalid-login-credentials") {
        setError("Email atau kata sandi salah");
      } else {
        setError(err.message);
      }
    }
  };

  return (
    <Container className="d-flex justify-content-center align-items-center vh-100">
      <Form onSubmit={handleLogin} className="w-50">
        <h2>Masuk</h2>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            autoComplete="username"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="formBasicPassword">
          <Form.Label>Kata Sandi</Form.Label>
          <Form.Control
            type="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </Form.Group>
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "20px" }}
        >
          <Button
            variant="primary"
            type="submit"
            style={{ marginRight: "10px" }}
          >
            Masuk
          </Button>
          <div>
            Belum punya akun? Silahkan daftar{" "}
            <Link to="/register">di sini</Link>.
          </div>
        </div>
        {/* <Button variant="primary" type="submit" className="mt-3">
          Masuk
        </Button>
        <div className="mt-3">
          Belum punya akun? Silahkan daftar <Link to="/register">di sini</Link>.
        </div> */}
      </Form>
    </Container>
  );
};

export default LoginPage;

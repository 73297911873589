import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import firebaseApp from '../firebase/init';
import { getDatabase, ref, get } from 'firebase/database';
import { formatDate, formatNumber } from '../helpers/utils';
const db = getDatabase(firebaseApp);

function InvoiceDetail() {
  const [invoice, setInvoice] = useState();
  const params = useParams();
  const shouldReadData = useRef(true);

  useEffect(() => {
    if (shouldReadData.current) {
      const id = params.id;
      const dbRef = ref(db, '/invoices/' + id);
      get(dbRef).then((snapshot) => {
        const data = snapshot.val();
        setInvoice(data);
      });

      shouldReadData.current = false;
    }
  }, [params.id]);

  if (!invoice) {
    return <>Invoice Not found</>;
  }
  return (
    <Container>
      <Row className="justify-content-center mt-3">
        <Col md={8} className="mx-auto">
          <Row style={{ height: '200px' }}>
            <Col md={12} xs={12}>
              <img src="/kf-logo.jpeg" alt="kimia farma logo" width="320" />
            </Col>

            <Col
              md={{ span: 10, offset: 2 }}
              xs={{ span: 10, offset: 2 }}
              className="d-flex "
              style={{
                flexDirection: 'column',
                justifyContent: 'flex-end',
                textAlign: 'right',
              }}
            >
              <strong>
                PT KIMIA FARMA DIAGNOSTIKA UNIT BISNIS JAKARTA 2 <br />
                Jl. Garuda No.47, RW.4, Gn. Sahari Sel, Kota Jakarta Pusat 10610
              </strong>
            </Col>
          </Row>
          <Row>
            <Col md={6} xs={6} className="p-1">
              <strong>INVOICE / OFFICIAL RECEIPT</strong>
              <section
                className="box-border--solid p-2 box-invoice"
                style={{ minHeight: '120px' }}
              >
                To: <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <strong>{invoice.company}</strong>
              </section>
            </Col>
            <Col md={6} xs={6} className="mt-4 p-1">
              <section
                className="box-border--solid p-2"
                style={{ minHeight: '120px' }}
              >
                <table>
                  <tbody style={{ fontSize: '14px' }}>
                    <tr>
                      <td>Jatuh tempo</td>
                      <td>:</td>
                      <td>{formatDate(invoice.dueDate)}</td>
                    </tr>
                    <tr>
                      <td>Statement No</td>
                      <td>:</td>
                      <td>{invoice.noInvoice}</td>
                    </tr>
                    <tr>
                      <td>Pembuatan</td>
                      <td>:</td>
                      <td>{formatDate(invoice.createdAt)}</td>
                    </tr>
                    <tr>
                      <td>Periode</td>
                      <td>:</td>
                      <td>{invoice.period}</td>
                    </tr>
                  </tbody>
                </table>
              </section>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="p-1">
              <section className="box-border--solid p-2 mt-3 text-center">
                <label htmlFor="table-invoice">
                  <p>
                    <strong>
                      REKAPITULASI PERIODE {invoice.period.toUpperCase()}
                    </strong>
                  </p>
                </label>
                <table
                  name="table-invoice"
                  className="table table-bordered "
                  style={{ borderColor: '#000' }}
                >
                  <thead>
                    <tr>
                      <td width="10">
                        <strong>No</strong>
                      </td>
                      <td width="180">
                        <strong>Nama Klinik Kimia Farma</strong>
                      </td>
                      <td width="250">
                        <strong>Jenis Pemeriksaan</strong>
                      </td>
                      <td width="100">
                        <strong>Nominal</strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>{invoice.branch}</td>
                      <td>{invoice?.examination}</td>
                      <td>{formatNumber(invoice.totalBill)}</td>
                    </tr>
                    <tr style={{ background: '#b4d6fa' }}>
                      <td colSpan={3}>
                        <strong>Grand Total</strong>
                      </td>
                      <td>
                        <strong>{formatNumber(invoice.totalBill)}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>
            </Col>
          </Row>
          <Row>
            <Col md={8} xs={8} className="p-1">
              <section className="box-border--solid mt-3 p-2 h-300">
                <p>
                  <strong>
                    Note: <br />
                    PT. Kimia Farma Diagnostika <br />
                    Bank Mandiri KCP Jakarta Juanda <br />
                    IDR A/C. No. 119-000-6108-409
                  </strong>
                </p>
                <p>
                  Apabila telah dilakukan pembayaran mohon konfirmasi dengan
                  mengirimkan bukti transfer pada Finance &amp; Accounting
                  Department melalui
                </p>
                <ul>
                  <li>
                    <strong>
                      Phone (WA) :089614857680 ARIF/ 08118740034 INA FITRI
                    </strong>
                  </li>
                  <li>
                    <strong>Email :piutangbmjakarta2@gmail.com</strong>
                  </li>
                </ul>
              </section>
            </Col>
            <Col md={4} xs={4} className="m-0 p-1">
              <section
                className="box-border--solid mt-3 h-300 text-center"
                style={{
                  alignItems: 'flex-end',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <p className="text-center">
                  <strong>(Heru Sudomo)</strong>
                  <br />
                  <strong>Bisnis Manager Jakarta 2</strong>
                </p>
              </section>
            </Col>
          </Row>
          <Row>
            <Col
              md={{ offset: 10, span: 2 }}
              className="my-3 text-right d-print-none "
            >
              <Button color="primary" onClick={() => window.print()}>
                Cetak
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default InvoiceDetail;

import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Modal,
  Form,
  InputGroup,
  Col,
  Row,
  Container,
} from "react-bootstrap";
import Papa from "papaparse";

import firebaseApp, { auth } from "../firebase/init";
import {
  getDatabase,
  ref,
  push,
  onValue,
  remove,
  set,
} from "firebase/database";
import InvoiceTable from "./invoice-table";
import { useNavigate } from "react-router-dom";

const db = getDatabase(firebaseApp);
const dbRef = ref(db, "invoices");

const InvoiceList = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [filteredInv, setFilteredInv] = useState(null);
  const [editInvoice, setEditInvoice] = useState(null);
  const [deleteInvoice, setDeleteInvoice] = useState(null);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const filterRef = useRef("invoice");
  const [file, setFile] = useState(null);
  const shouldReadData = useRef(true);
  const navigate = useNavigate();

  const handleCreateModal = () => setShowCreateModal(!showCreateModal);
  const handleEditModal = (invoice) => {
    setEditInvoice(invoice);
    setShowEditModal(!showEditModal);
  };
  const handleDeleteModal = (invoice) => {
    setDeleteInvoice(invoice);
    setShowDeleteModal(!showDeleteModal);
  };

  const handleCreateInvoice = (event) => {
    event.preventDefault();
    const form = event.target;
    const no = invoices.length + 1;
    const noInvoice = form.elements.noInvoice.value;
    const company = form.elements.company.value;
    const totalBill = parseInt(form.elements.totalBill.value);
    const createdAt = form.elements.createdAt.value;
    const period = form.elements.period.value;
    const dueDate = form.elements.dueDate.value;
    const examination = form.elements.examination.value;
    const branch = form.elements.branch.value;
    const data = {
      no,
      noInvoice,
      company,
      totalBill,
      createdAt,
      period,
      dueDate,
      examination,
      branch,
    };
    push(dbRef, data);
    setShowCreateModal(false);
  };

  const handleEditInvoice = (event) => {
    event.preventDefault();
    const form = event.target;
    const no = editInvoice.no;
    const noInvoice = form.elements.noInvoice.value;
    const company = form.elements.company.value;
    const totalBill = parseInt(form.elements.totalBill.value);
    const createdAt = form.elements.createdAt.value;
    const period = form.elements.period.value;
    const dueDate = form.elements.dueDate.value;
    const examination = form.elements.examination.value;
    const branch = form.elements.branch.value;
    const updatedInvoice = {
      no,
      noInvoice,
      company,
      totalBill,
      createdAt,
      period,
      dueDate,
      examination,
      branch,
    };
    const dbRef = ref(db, "/invoices/" + editInvoice.key);
    set(dbRef, updatedInvoice);
    setEditInvoice(null);
    setShowEditModal(false);
  };

  const handleDeleteInvoice = () => {
    const key = deleteInvoice.key;
    const dbRef = ref(db, "/invoices/" + key);
    remove(dbRef);
    setShowDeleteModal(!showDeleteModal);
  };

  const handleDeleteSelectedInvoices = () => {
    selectedInvoices.forEach((invoiceKey) => {
      const dbRef = ref(db, "/invoices/" + invoiceKey);
      remove(dbRef);
    });
    setSelectedInvoices([]);
    setShowDeleteModal(!showDeleteModal);
  };

  const handlePrintInvoice = (invoice) => {
    navigate(`/invoice/${invoice.key}`);
  };

  const handleFile = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = () => {
    const reader = new FileReader();
    reader.onload = async function ({ target }) {
      const csv = Papa.parse(target.result, { header: true });
      const parsedData = csv?.data;
      parsedData.forEach((data) => {
        const newData = {
          no: data["NO"] || data["NO INVOICE"],
          branch: data["CABANG"],
          dueDate: data["JATUH TEMPO"],
          examination: data["JENIS PEMERIKSAAN"],
          noInvoice: data["NO INVOICE"],
          createdAt: data["PEMBUATAAN"],
          company: data["PERUSAHAAN"],
          period: data["PERIODE"],
          totalBill: data["TOTAL TAGIHAN"],
        };
        push(dbRef, newData);
      });
    };
    if (!file) {
      alert("silahkan pilih file terlebih dahulu");
    } else {
      reader.readAsText(file);
    }
  };

  const handleSearch = (event) => {
    const keyword = event.target.value;
    const filter = filterRef.current.value;
    const data = invoices.filter((invoice) => {
      return invoice[filter].includes(keyword);
    });
    setFilteredInv(data);
  };

  const handleSelectInvoice = (invoiceKey) => {
    setSelectedInvoices((prev) => {
      if (prev.includes(invoiceKey)) {
        return prev.filter((key) => key !== invoiceKey);
      } else {
        return [...prev, invoiceKey];
      }
    });
  };

  const logoutHandler = async () => {
    try {
      await auth.signOut();
      navigate("/login"); // Redirect to login page after logout
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const handleSelectAllInvoices = () => {
    if (selectedInvoices.length === invoices.length) {
      setSelectedInvoices([]);
    } else {
      setSelectedInvoices(invoices.map((invoice) => invoice.key));
    }
  };

  useEffect(() => {
    if (shouldReadData.current) {
      const db = getDatabase(firebaseApp);
      const dbRef = ref(db, "invoices");
      onValue(dbRef, (data) => {
        const newData = [];
        data.forEach((dt) => {
          newData.push({
            key: dt.key,
            ...dt.val(),
          });
        });
        setInvoices(newData);
      });
      shouldReadData.current = false;
    }
  }, [invoices]);

  return (
    <Container>
      <Row>
        <Col md="6">
          <Form className="py-3">
            <InputGroup>
              <Col md="3">
                <Button
                  variant="primary"
                  className="md-2"
                  onClick={handleCreateModal}
                >
                  Buat
                </Button>
              </Col>
              <Form.Control
                type="file"
                aria-label="Upload invoice file"
                aria-describedby="basic-addon1"
                onChange={handleFile}
              />
              <Button
                variant="outline-secondary"
                id="button-addon1"
                onClick={handleUpload}
              >
                Upload
              </Button>
            </InputGroup>
          </Form>
        </Col>
        <Col md="6">
          <Button
            variant="outline-danger"
            className="float-end"
            onClick={logoutHandler}
          >
            Keluar
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <Form>
            <InputGroup>
              <Col md="3">
                <Form.Select ref={filterRef}>
                  <option value="noInvoice">No invoice</option>
                  <option value="branch">Cabang</option>
                  <option value="company">Perusahaan</option>
                  <option value="totalBill">Total Tagihan</option>
                  <option value="period">Periode</option>
                </Form.Select>
              </Col>
              <Form.Control
                type="text"
                placeholder="cari"
                onChange={handleSearch}
              />
            </InputGroup>
          </Form>
        </Col>
      </Row>

      <Row className="py-3">
        <Col md="3">
          <Button
            variant="danger"
            onClick={() => handleDeleteModal(selectedInvoices)}
            disabled={selectedInvoices.length === 0}
          >
            Hapus Terpilih
          </Button>
        </Col>
      </Row>
      <InvoiceTable
        invoices={filteredInv ? filteredInv : invoices}
        handleEditModal={handleEditModal}
        handleDeleteModal={handleDeleteModal}
        handlePrintInvoice={handlePrintInvoice}
        handleSelectInvoice={handleSelectInvoice}
        handleSelectAllInvoices={handleSelectAllInvoices}
        selectedInvoices={selectedInvoices}
      />

      <Modal show={showCreateModal} onHide={handleCreateModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleCreateInvoice}>
            <Form.Group controlId="formNoInvoice">
              <Form.Label>Invoice No.</Form.Label>
              <Form.Control type="text" name="noInvoice" required />
            </Form.Group>
            <Form.Group controlId="formBranch">
              <Form.Label>Cabang</Form.Label>
              <Form.Control type="text" name="branch" required />
            </Form.Group>
            <Form.Group controlId="formCompany">
              <Form.Label>Perusahaan</Form.Label>
              <Form.Control type="text" name="company" required />
            </Form.Group>
            <Form.Group controlId="formExamination">
              <Form.Label>Jenis Pemeriksaan</Form.Label>
              <Form.Control type="text" name="examination" required />
            </Form.Group>
            <Form.Group controlId="formTotalBill">
              <Form.Label>Total Tagihan</Form.Label>
              <Form.Control type="number" name="totalBill" required />
            </Form.Group>
            <Form.Group controlId="formCreatedAt">
              <Form.Label>Tanggal pembuatan</Form.Label>
              <Form.Control type="date" name="createdAt" required />
            </Form.Group>
            <Form.Group controlId="formPeriod">
              <Form.Label>Period</Form.Label>
              <Form.Control type="text" name="period" required />
            </Form.Group>
            <Form.Group controlId="formDueDate">
              <Form.Label>Jatuh tempo</Form.Label>
              <Form.Control type="date" name="dueDate" required />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-2">
              Simpan
            </Button>
            <Button
              variant="secondary"
              className="mt-2"
              onClick={handleCreateModal}
            >
              Batal
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      {editInvoice && (
        <Modal show={showEditModal} onHide={handleEditModal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Invoice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleEditInvoice}>
              <Form.Group controlId="formNoInvoice">
                <Form.Label>Invoice No.</Form.Label>
                <Form.Control
                  type="text"
                  name="noInvoice"
                  defaultValue={editInvoice.noInvoice}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formBranch">
                <Form.Label>Cabang</Form.Label>
                <Form.Control
                  type="text"
                  name="branch"
                  defaultValue={editInvoice.branch}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formCompany">
                <Form.Label>Perusahaan</Form.Label>
                <Form.Control
                  type="text"
                  name="company"
                  defaultValue={editInvoice.company}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formExamination">
                <Form.Label>Jenis Pemeriksaan</Form.Label>
                <Form.Control
                  type="text"
                  name="examination"
                  defaultValue={editInvoice.examination}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formTotalBill">
                <Form.Label>Total Tagihan</Form.Label>
                <Form.Control
                  type="number"
                  name="totalBill"
                  defaultValue={editInvoice.totalBill}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formCreatedAt">
                <Form.Label>Tanggal Pembuatan</Form.Label>
                <Form.Control
                  type="date"
                  name="createdAt"
                  defaultValue={editInvoice.createdAt}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formPeriod">
                <Form.Label>Periode</Form.Label>
                <Form.Control
                  type="text"
                  name="period"
                  defaultValue={editInvoice.period}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formDueDate">
                <Form.Label>Jatuh tempo</Form.Label>
                <Form.Control
                  type="date"
                  name="dueDate"
                  defaultValue={editInvoice.dueDate}
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="mt-2">
                Simpan
              </Button>{" "}
              <Button
                variant="secondary"
                className="mt-2"
                onClick={handleEditModal}
              >
                Batal
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      )}
      {deleteInvoice && (
        <Modal show={showDeleteModal} onHide={handleDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Invoice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Yakin ingin hapus invoice{" "}
            {Array.isArray(deleteInvoice) && deleteInvoice.length > 0
              ? "yang dipilih"
              : deleteInvoice.noInvoice}
            ?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              onClick={() =>
                Array.isArray(deleteInvoice) && deleteInvoice.length > 0
                  ? handleDeleteSelectedInvoices()
                  : handleDeleteInvoice(deleteInvoice)
              }
            >
              Hapus
            </Button>{" "}
            <Button variant="secondary" onClick={handleDeleteModal}>
              Batal
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
};

export default InvoiceList;

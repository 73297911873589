export function formatDate(date) {
  const newDate = new Date(date);
  const month = [
    'January',
    'February',
    'Maret',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Agustus',
    'September',
    'Oktober',
    'November',
    'Desember',
  ];
  const dateStr = newDate.getDate();
  const monthStr = month[newDate.getMonth()];
  const yearStr = newDate.getFullYear();
  return `${dateStr} ${monthStr} ${yearStr}`;
}
export function formatNumber(number) {
  const result = number
    .toLocaleString('en-US', {
      useGrouping: true,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      minimumIntegerDigits: 1,
      style: 'decimal',
    })
    .replace(/,/g, '.');
  return `Rp ${result}`;
}
